<template>
    <v-card height="100%">
        <v-toolbar flat outlined>
            <v-icon @click="close()">mdi-close</v-icon>

            <v-spacer />

            <v-toolbar-title>New transaction</v-toolbar-title>

            <v-spacer />

            <v-btn small text :disabled="saveDisabled" @click="save"
                >Save</v-btn
            >
        </v-toolbar>

        <v-card-text>
            <v-container>
                <v-form class="mt-3">
                    <v-row align="end">
                        <v-btn outlined block @click="openItemPicker">
                            <v-icon>mdi-magnify</v-icon>
                            <span>Find Product</span>
                        </v-btn>
                    </v-row>
                    <v-row v-if="itemCode">
                        <div class="item-code mt-3">
                            {{ itemCode }}
                        </div>
                    </v-row>
                    <v-row v-if="itemDescription">
                        <div class="item-description mt-3">
                            {{ itemDescription }}
                        </div>
                    </v-row>
                    <v-row v-if="itemCode && itemDescription">
                        <v-text-field
                            class="mt-3"
                            label="Quantity"
                            type="number"
                            v-model="itemQuantity"
                            placeholder="Quantity"
                            :rules="quantityRules"
                        />
                    </v-row>
                </v-form>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
import payments from "./paymentsAgent";
import { required, isInteger, greaterThan } from "@/validation";

const GoodsAndServicesPicker = () => import("./GoodsAndServicesPicker");

export default {
    props: {
        payment: {
            required: true,
        },
    },
    data: function () {
        return {
            itemCode: "",
            itemQuantity: 1,
            itemDescription: "",
            amount: -1,
            vatAmount: -1,
            transactionType: "",
            itemUnitOfMeasure: "",
            itemPrice: -1,
            adjustmentCode: "",
            adjustmentDescription: "",
            adjustmentRate: -1,
            manualAmountEntry: -1,
            chartOfAccountsCode: null,
        };
    },
    computed: {
        quantityRules: function () {
            return [required(), isInteger(), greaterThan(0)];
        },
        saveDisabled: function () {
            return (
                !this.itemCode ||
                !this.itemDescription ||
                !(this.itemQuantity > 0)
            );
        },
    },
    watch: {
        itemQuantity: function (value) {
            this.amount = this.itemPrice * value;
        },
    },
    methods: {
        openItemPicker: function () {
            this.$coreUi.dialog(
                GoodsAndServicesPicker,
                {
                    sharedoId: this.payment.parentSharedoId,
                    paymentRequestId: this.payment.aspectData.paymentRequest.id,
                },
                {
                    closing: (result) => {
                        if (result) {
                            this.itemCode = result.itemCode;
                            this.itemDescription = result.itemDescription;
                            this.amount = result.value * this.itemQuantity;
                            this.vatAmount = result.vatRate;
                            this.itemUnitOfMeasure = result.unitOfMeasurement;
                            this.itemPrice = result.value;
                        }
                    },
                }
            );
        },
        save: async function () {
            const loading = this.$coreUi.loading();

            try {
                const request = {
                    paymentRequestModel: {
                        parentSharedoId: this.payment.parentSharedoId,
                        paymentRequestType: this.payment.sharedoTypeSystemName,
                        supplierOdsId:
                            this.payment.aspectData.paymentRequest
                                .supplierOdsId,
                        rechargeToOdsId:
                            this.payment.aspectData.paymentRequest
                                .rechargeToOdsId,
                        details: this.payment.aspectData.paymentRequest.details,
                        transactionItems: [
                            {
                                chartOfAccountsCodeIds: [
                                    this.chartOfAccountsCode.id,
                                ],
                                transactionType: "paymentItemSku",
                                currency: "GBP",
                                amount: this.amount,
                                vatAmount: this.vatAmount,
                                itemCode: this.itemCode,
                                itemDescription: this.itemDescription,
                                itemUnitOfMeasure: this.itemUnitOfMeasure,
                                itemQuantity: this.itemQuantity,
                                itemPrice: this.itemPrice,
                            },
                        ],
                    },
                };

                await payments.savePayment(this.payment.id, request);
                this.close(true);
            } catch (error) {
                console.error(error);
            } finally {
                loading.dismiss();
            }
        },
        close: function (result) {
            this.$emit("close", result);
        },
    },
    mounted: async function () {
        this.chartOfAccountsCode = await payments.getChartOfAccountsCode(
            this.payment.parentSharedoId,
            this.payment.sharedoTypeSystemName
        );
    },
};
</script>
<style lang="scss" scoped>
.item-code {
    font-weight: bold;
}
</style>